.services__container {
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
	gap: 2rem;
  }
  
  .services__content {
	background-color: var(--container-color);
	padding: 2rem;
	border-radius: 1rem;
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .services__icon {
	font-size: 2rem;
	color: var(--title-color);
	margin-bottom: 1rem;
  }
  
  .services__title {
	font-size: var(--h3-font-size);
	margin-bottom: 1rem;
	font-weight: var(--font-medium);
  }
  
  .services__button {
	color: var(--title-color);
	font-size: var(--small-font-size);
	display: flex;
	align-items: center;
	column-gap: 0.25rem;
	cursor: pointer;
  }
  
  .services__button-icon {
	transition: 0.3s;
  }
  
  .services__button:hover .services__button-icon {
	transform: translateX(0.25rem);
  }
  
  .services__modal {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: rgba(0, 0, 0, 0.5);
	z-index: var(--z-modal);
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 1rem;
	opacity: 0;
	visibility: hidden;
	transition: 0.3s;
  }
  
  .services__modal-content {
	width: 100%;
	max-width: 500px;
	background-color: var(--container-color);
	padding: 2.5rem;
	border-radius: 1rem;
	position: relative;
  }
  
  .services__modal-close {
	position: absolute;
	top: 1rem;
	right: 1rem;
	font-size: 1.5rem;
	color: var(--first-color);
	cursor: pointer;
	background: linear-gradient(135deg, #ff6b6b, #feca57);
	border: none;
	border-radius: 50%;
	width: 2.5rem;
	height: 2.5rem;
	display: flex;
	justify-content: center;
	align-items: center;
	transition: 0.3s;
  }
  
  .services__modal-close:hover {
	transform: rotate(90deg);
	background: linear-gradient(135deg, #ff4757, #ffa502);
  }
  
  .services__modal-title {
	font-size: var(--h3-font-size);
	font-weight: var(--font-medium);
	margin-bottom: 1rem;
  }
  
  .services__modal-description {
	font-size: var(--small-font-size);
	margin-bottom: 2rem;
  }
  
  .services__modal-services {
	display: grid;
	row-gap: 0.75rem;
  }
  
  .services__modal-service {
	display: flex;
	align-items: flex-start;
	column-gap: 0.5rem;
  }
  
  .services__modal-icon {
	color: var(--first-color);
	font-size: 1.1rem;
  }
  
  .services__modal-info {
	font-size: var(--small-font-size);
  }
  
  .active-modal {
	opacity: 1;
	visibility: visible;
  }