/* General Styles */
.qualification__container {
	max-width: 768px;
	margin: 0 auto;
	padding: 2rem 0;
  }
  
  .qualification__headers {
	display: flex;
	justify-content: center;
	gap: 3rem;
	margin-bottom: 3rem;
  }
  
  .qualification__header {
	display: flex;
	align-items: center;
	column-gap: 1rem;
	cursor: pointer;
  }
  
  .qualification__icon {
	font-size: 1.8rem;
	color: var(--title-color);
  }
  
  .qualification__header-title {
	font-size: var(--h3-font-size);
	font-weight: var(--font-medium);
	color: var(--title-color);
  }
  
  .qualification__timeline {
	position: relative;
  }
  
  .qualification__timeline::after {
	content: '';
	position: absolute;
	width: 2px;
	background-color: var(--title-color);
	top: 0;
	bottom: 0;
	left: 50%;
	margin-left: -1px;
  }
  
  .qualification__item {
	padding: 0 40px 2rem;
	position: relative;
	background-color: inherit;
	width: 50%;
  }
  
  .qualification__item::after {
	content: '';
	position: absolute;
	width: 16px;
	height: 16px;
	right: -8px;
	background-color: var(--container-color);
	border: 2px solid var(--title-color);
	top: 0;
	border-radius: 50%;
	z-index: 1;
  }
  
  .qualification__item.left {
	left: 0;
  }
  
  .qualification__item.right {
	left: 50%;
  }
  
  .qualification__item.left::before {
	content: " ";
	height: 0;
	position: absolute;
	top: 7px;
	width: 0;
	z-index: 1;
	right: 30px;
	border: medium solid var(--title-color);
	border-width: 10px 0 10px 10px;
	border-color: transparent transparent transparent var(--title-color);
  }
  
  .qualification__item.right::before {
	content: " ";
	height: 0;
	position: absolute;
	top: 7px;
	width: 0;
	z-index: 1;
	left: 30px;
	border: medium solid var(--title-color);
	border-width: 10px 10px 10px 0;
	border-color: transparent var(--title-color) transparent transparent;
  }
  
  .qualification__item.right::after {
	left: -8px;
  }
  
  .qualification__content {
	padding: 1.5rem;
	background-color: var(--container-color);
	border-radius: 0.75rem;
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
  }
  
  .qualification__title {
	font-size: var(--normal-font-size);
	font-weight: var(--font-medium);
	margin-bottom: 0.5rem;
  }
  
  .qualification__subtitle {
	display: inline-block;
	font-size: var(--small-font-size);
	margin-bottom: 0.5rem;
  }
  
  .qualification__calendar {
	font-size: var(--smaller-font-size);
	color: var(--text-color-light);
  }
  
  .qualification__calendar i {
	margin-right: 0.25rem;
  }
  
  /* Mobile Responsive */
  @media screen and (max-width: 768px) {
	.qualification__container {
	  padding: 1rem;
	}
  
	.qualification__timeline {
	  position: relative;
	}
  
	.qualification__timeline::after {
	  left: 50%;
	}
  
	.qualification__item {
	  width: 100%;
	  padding-left: 0;
	  padding-right: 0;
	}
  
	.qualification__item.left,
	.qualification__item.right {
	  left: 0;
	  width: 50%;
	}
  
	.qualification__item.left {
	  padding-right: 20px;
	}
  
	.qualification__item.right {
	  left: 50%;
	  padding-left: 20px;
	}
  
	.qualification__item.left::before {
	  right: 10px;
	}
  
	.qualification__item.right::before {
	  left: 10px;
	}
  
	.qualification__item::after {
	  width: 12px;
	  height: 12px;
	}
  
	.qualification__item.left::after {
	  right: -6px;
	}
  
	.qualification__item.right::after {
	  left: -6px;
	}
  
	.qualification__content {
	  padding: 0.75rem;
	}
  
	.qualification__title {
	  font-size: calc(var(--small-font-size) * 0.9);
	}
  
	.qualification__subtitle,
	.qualification__calendar {
	  font-size: calc(var(--smaller-font-size) * 0.9);
	}
  }
  
  /* Extra small devices */
  @media screen and (max-width: 350px) {
	.qualification__item.left,
	.qualification__item.right {
	  width: 100%;
	  left: 0;
	}
  
	.qualification__timeline::after {
	  left: 0;
	}
  
	.qualification__item.left,
	.qualification__item.right {
	  padding-left: 20px;
	  padding-right: 0;
	}
  
	.qualification__item::before,
	.qualification__item::after {
	  left: 0;
	}
  
	.qualification__item::before {
	  border-width: 10px 10px 10px 0;
	  border-color: transparent var(--title-color) transparent transparent;
	}
  }